import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { CurveSVG } from 'ha/components/CurveSVG';

import { HeroSectionIntroImage } from './HeroSectionIntroImage';

const useStyles = makeStyles()(theme => ({
  container: {
    position: 'sticky',
    bottom: 0,
    textAlign: 'center',
  },
  svgCurve: {
    fill: theme.palette.mono.light,
  },
}));

export const HeroSectionFooterDesktop = React.forwardRef<HTMLDivElement>(
  (_, ref) => {
    const { classes } = useStyles();

    return (
      <div className={classes.container} ref={ref}>
        <HeroSectionIntroImage />
        <CurveSVG className={classes.svgCurve} />
      </div>
    );
  },
);

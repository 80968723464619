import React from 'react';

import { Organization, WebSite } from 'ha/components.legacy/LinkedData';

import {
  HeroSection,
  USPSection,
  StepsToBook,
  FeaturedCities,
  GuaranteedSection,
  PopularCities,
  FAQSection,
  TestimonialsSection,
  FindYourHomeSection,
} from '../sections';

import { HomeHelmet } from './HomeHelmet';

const HomeLayout: React.FC = () => {
  const searchInputRef = React.useRef(null);
  const setSearchInputFocus = React.useCallback(() => {
    if (searchInputRef.current) {
      (searchInputRef.current as HTMLInputElement).focus();
    }
  }, [searchInputRef]);

  return (
    <div>
      <HomeHelmet />
      <Organization />
      <WebSite />
      <HeroSection searchInputRef={searchInputRef} />
      <USPSection />
      <PopularCities />
      <GuaranteedSection />
      <StepsToBook />
      <TestimonialsSection />
      <FindYourHomeSection setSearchInputFocus={setSearchInputFocus} />
      <FeaturedCities />
      <FAQSection />
    </div>
  );
};

export { HomeLayout };
